import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { BioSection, BlogSection, BookHero, QuoteSection, BookPreviewSection, InterviewSection } from "../components/Pages/Home";
import { useSiteMetadata } from "src/hooks";
import { SEO } from "gatsby-plugin-seo";
import { MailSignUp, Section } from "../components/UI";
export const _frontmatter = {
  "title": "PERPETUAL",
  "description": "Howard Rachinski's website. Find information about Howard's new book - Perpetual. Read blog posts from Howard. Sign up for Howard's newsletter."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  pageContext,
  children
}) => {
  const {
    siteUrl
  } = useSiteMetadata(); // // TODO add date published and other meta data
  // // TODO add publish date after launch
  //       {/* "datePublished": "2015-05-01", */}
  // // TODO add offer
  //         {/* "offers": {
  //         "@type": "Offer",
  //         "availability": "http://schema.org/InStock",
  //         "price": "9.99",
  //         "priceCurrency": "USD"
  //       }, */}
  // // TODO multiple formats
  //       {/* "bookFormat": "http://schema.org/EBook", */}

  return <Layout>
      <SEO schema={`{
            "@context": "http://schema.org",
            "@type": "WebPage",
            "mainEntity": {
              "@type": "Book",
              "author": "${siteUrl}/about",
              "image": "${siteUrl}/img/PERPETUAL_Cover.jpg",
              "inLanguage": "English",
              "numberOfPages": "189",
              "isbn": "978-0-9911311-6-7",
              "name": "PERPETUAL",
              "publisher": "Cool River Pub"
            }
          }`} keywords="perpetual, book" pagePath="/" title={pageContext.frontmatter.title} description={pageContext.frontmatter.description}>
        <meta name="something" content="otherthing" />
      </SEO>
      {children}
    </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <BookHero mdxType="BookHero" />
    <QuoteSection mdxType="QuoteSection" />
    <BookPreviewSection mdxType="BookPreviewSection" />
    <InterviewSection mdxType="InterviewSection" />
    <BioSection mdxType="BioSection" />
    <BlogSection mdxType="BlogSection" />
    <Section className="pb-10 sm:pb-20 bg-gray-100 flex" mdxType="Section">
  <div>
    <MailSignUp mdxType="MailSignUp" />
  </div>
    </Section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      